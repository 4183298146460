@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');

:root {
  --primary-color: #c98d1d;
  --secondary-color: #202020;
}


@font-face {
  font-family: inter;
  src: url('./Assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
  font-family: outfit;
  src: url(./Assets/fonts/Outfit-VariableFont_wght.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: inter;
}

.hero-text {
  font-family: "Poetsen One", sans-serif;

}

.hides {
  overflow: hidden;
}

.logo {

  width: 60px;
  height: 60px;
  object-fit: cover;
}

.top-section {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: -1;
}

.service {
  background-image: linear-gradient(rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.5)), url("./Assets/images/service.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: left;
}

.project {
  background-image: linear-gradient(rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.5)), url("./Assets/images/project-main-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: left;
}

.projectindu {
  background-image: linear-gradient(rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.5)), url(./Assets/images/project-card-1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: left;
}

.About {
  background-image: linear-gradient(rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.5)), url("./Assets/images/about-1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: left;
}

.home {
  background-image: linear-gradient(rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.5)), url('./Assets/images/main-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: -1;
}

.hero-sections {
  position: relative;

}

.nav-links {
  color: #fff !important;
  position: relative;
  margin: 0px 20px;
  font-size: 16px !important;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.nav-links::after {
  position: absolute;
  content: '';
  width: 0%;
  height: 2px;
  background-color: #fff;
  left: 0;
  bottom: 0;
  transition: 1s ease-in-out;
}

.nav-links:hover::after {
  width: 100%;

}

.active.nav-links::after {
  width: 100%;
}

.navbar-top {
  z-index: 10;
}

.active-link {
  border-bottom: 2px solid #fff !important;
  color: red;
  font-weight: 600;
}



section {
  margin-bottom: 80px;
}

.section-title {
  position: relative;
  font-size: 50px;
  color: #1E1E1E;
  width: fit-content;
  font-weight: 400;
}

.second-hero-section {
  width: 100%;
  z-index: 1;
  position: relative;
  top: 100px;
}

.second-hero-section img {
  width: 100%;
  height: auto;
}

.contact-button {
  background-color: var(--primary-color);
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 15px;
}

.center-text {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.center-text h1 {
  font-size: 120px;
  font-weight: 200 !important;
}

.torque-Text {

  color: #fff;
  -webkit-text-stroke: 2px #000000;
  position: relative;
  margin-top: 200px;
  margin-bottom: 50px;
}

.torque-Text h2 {
  font-size: 90px;
}

.count-num {
  color: var(--primary-color);
  font-size: 80px;
  font-weight: 400;
}

.count-text {
  font-size: 24px;
}

.counter-con {
  text-align: center;
}

.card-img {
  width: 100%;
  height: 400px;
  /* background-color: #000000; */
  position: relative;

}

.card-img::after {
  background-color: rgb(0, 0, 0, 0.3);
  content: '';
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 89%;
  margin: 20px;
  overflow: hidden;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 20px;


}

.card-text {
  color: #fff;
  font-size: 20px;
  position: absolute;
  bottom: 2px;
  left: 30px;
  padding: 20px;
  z-index: 1;

}

.slider-top-con {
  padding: 80px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-top-con h2 {
  font-size: 70px;
}

.arrow-button button {
  padding: 10px 20px;
  margin: 0px 10px;
  border: 1px solid;
  outline: none;
  visibility: hidden;
}

.arrow-button button:hover {
  color: #fff;
  background: var(--primary-color);
  border: 1px solid #fff;
}

.r-arrow {
  color: #fff;
  background: var(--primary-color);
  border: 1px solid #fff;
}

.arcitech-text {
  position: relative;
  font-size: 60px;
  color: #1E1E1E;
  width: fit-content;
  margin: 50px 0px;
  font-weight: 400;
}

.arcitech-text::after {
  position: absolute;
  content: '';
  top: 0;
  left: 20px;
  width: 30%;
  height: 2px;
  background-color: #000000;
}

.why-us {
  border-bottom: 1px solid #1E1E1E;
}

.banner-section {
  background: linear-gradient(rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url(./Assets/images/sec-banner.png);
  width: 100%;
  height: 550px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;
}

.banner-center-text {
  color: #fff;
  text-align: center;
}

.banner-center-text h6 {
  font-weight: 400;
  font-size: 32px;
  padding-bottom: 30px;
}

.banner-center-text p {
  font-size: 24px;
}

.accordion {
  width: 100%;
  max-width: 470px;
}

.panel {
  background-color: #f0ebe1;
}

.panel__label {
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 25px 60px 25px 25px;
  font-weight: 500;
  font-size: 17px;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
}

.panel__label:focus {
  outline: none;
}

.panel__label:after,
.panel__label:before {
  content: '';
  position: absolute;
  right: 25px;
  top: 50%;
  width: 22px;
  height: 2px;
  margin-top: -2px;
  background-color: #372717;
}

.panel__label:before {
  transform: rotate(-90deg);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel[aria-expanded='true'] .panel__content {
  opacity: 1;
}

.panel[aria-expanded='true'] .panel__label {
  color: #957029;
}

.panel[aria-expanded='true'] .panel__label:before {
  transform: rotate(0deg);
}

.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel__content {
  margin: 5px 25px 25px;
  font-size: 14px;
  color: #756658;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}

.panel:not(:last-child) {
  margin-bottom: 3px;
}

.card-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1080px;
  position: relative;
  background: none;
  outline: none;
  border: none;
}

.cards {
  width: 1080px;
  background: none;
  outline: none;
  border: none;
  margin: 20px 0px;
  border-top: 1px solid #1E1E1E;
  padding: 30px 0px;
}

.toggleIcon {
  position: absolute;
  right: 20px;
  font-size: 30px;
  color: var(--primary-color);
}

.toggle-title {
  font-size: 30px;
}

.list-accordion {
  background-color: transparent;
  outline: none;
}

.numb {
  color: var(--primary-color);
}

.projects-title {
  width: fit-content;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 4px;
}

.projects-title::after {
  position: absolute;
  content: '';
  width: 50%;
  height: 2px;
  background-color: #1E1E1E;
  bottom: 0;
  left: 0;
}

.project-section {
  border-top: 1px solid #1E1E1E;
  padding-top: 70px;
}

.chg {
  border-bottom: 1px solid #1E1E1E;
  padding-bottom: 10px;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.main-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

footer {
  height: 130vh;
  width: 100%;
  background-color: #1E1E1E;
}

.footer-fz {
  max-width: 600px;
  margin: auto;
}

.center-footer-text {
  font-size: 70px;
  font-weight: 500;
  text-align: center;
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);

}

.small-color {
  color: #ACACAC;
}

.li-small {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.footer-contact-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-contact-details ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
  margin-bottom: 60px;
}
.fg{
  display: flex;
  align-items: center;
}

.address{
  max-width: 80%;
  font-size: 18px;
}
.foot-last a{
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
  width: fit-content;
}
.footer-contact-details ul li {
  padding: 0px 20px;
}
.footicon{
  font-size: 20px;
}
.foot-last a:hover{
  color: var(--primary-color);
}
.footer-link:hover{
  color: var(--primary-color);

}
.footer-contact-details ul li:hover{
  color: #fff !important;
  font-weight: 600;
}

.bottom-li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.bottom-li p {
  padding: 0px 20px;
  font-size: 14px;
}


/* =======services ========== */

.service-main-text {
  color: #fff;
  font-family: outfit;
}

.service-main-text h2 {
  font-size: 80px;
  padding: 0;
  margin: 0;
  max-width: 800px;
}

.sec-p {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1in;
}

.service-second {
  background-color: #fff;
  height: auto;
  padding: 100px 0px;
}

.projindu-section {
  background-color: #fff;
  height: auto;
  padding-top: 100px;
}

.service-sec-title h2 {
  font-size: 50px;
  font-family: outfit;
  color: #090B19;
}

.box-service {
  width: 90%;
  height: 300px;
  border: 1px solid #B6B6B6;
  /* padding: 10px; */
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.bottom-box-ser{
  background-color: var(--primary-color);
  padding: 10px;
  font-size: 22px;
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  
}
.box-center-ser-img{
  width: 100%;
  height: 90%;
  object-fit: cover;
  overflow: hidden;
  transition: 1s ease-in-out;
}
.box-center-ser-img:hover{
  transform: scale(1.1);
}

.box-circle-img {
  background-color: #C9A45D;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.box-circle-img img {
  object-fit: contain;
  width: 80%;
  height: 80%;
}

.box-service h3 {
  font-weight: 700;
  font-size: 24px;
}

.box-service p {
  color: #6E7488;
  font-family: outfit;
  font-size: 16px;
}

.services-third-sec {
  background-image: url('./Assets/images/service-thurd-img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #fff;

}

.third-bg-text {
  font-size: 40px;
  font-family: outfit;
  margin-bottom: 70px;
  margin-top: 20px;
}

.testimonial {
  background-color: #E6F0F4;
  padding: 60px 0px;
}

.testi-box-con {
  width: 50%;
  margin: 0 auto;
}

.testi-content {
  background-color: #fff;
  border-radius: 15px;
  margin: 50px auto;
  padding: 20px;
  display: flex;
  height: 200px;

}

.quote-mark {
  font-size: 50px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  position: relative;
  top: -30px;
}

.fourth-sec-title {
  font-family: outfit;
  font-size: 40px;
  font-weight: 700;
  margin-top: 100px;
  margin-bottom: 20px;
}

.fourth-img {
  margin-top: 20px;
}

.testi-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

}

.name {
  padding: 0;
  margin: 0;
}

.name-descs {
  display: none;
}

.inner-testi {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.testi-profile img {
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;

}


/* ========project========== */
.project-showcase {
  list-style: none;
}

.project-showcase li {
  color: #C2C7D6;
  border-left: 2px solid transparent;
  padding: 0px 10px;
  transition: 0.5s ease-in-out;
  margin: 10px 0px;
}

.project-showcase li:hover,
.active-card {
  color: #000000 !important;
  border-left: 2px solid #000 !important;
  cursor: pointer;
  font-weight: 700;
}

.wrapper-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 30px;
}

.wrapper-card a {
  text-decoration: none;
}

.cardss {
  width: 45%;
}

.cardss img {
  object-fit: contain;
}

.bot-card {
  background-color: #C9A45D;
  padding: 10px;
  color: #fff;

}

.card-second-img {
  width: 100% !important;
  position: relative;
  left: -20px;
}

.project-title-section {
  font-size: 60px;
  font-family: outfit;
  font-weight: 400;
}

.project-title-section2 {
  font-size: 20px;
  font-family: outfit;
}

.project-sec-para {
  color: #767676;
  line-height: 34px;
  font-size: 22px;

}

.list-para {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.proj-banner {
  background-image: url("./Assets/images/project-banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

.project-banner-sec {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30vh;
}

.project-banner-sec h2 {
  font-family: outfit;
}

.project-banner-sec button {
  border: 2px solid #fff;
  padding: 8px;
  color: #fff;
  font-weight: 600;
  background: transparent;
}

.projindu-part {
  padding-left: 40px;
}


.projindu-part ul li {
  list-style: none;
  padding: 10px 0;
}

.highlight {
  color: 2B4248;
  font-weight: 500;
  font-size: 22px;
}

.shadow-text {
  color: #767676;
}

.newslider,
.carousel-item {
  height: 100vh;
}

.banner-proj-indu {
  margin-top: 100px;
  background-image: url("https://vmassociatescbe.com/vm%20associates/images/induprojBanner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-bann {
  margin-top: 100px;
  background-image: url('./Assets/images/about-sec-banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-box {
  background-color: #fff;
  width: 450px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-box h2 {
  font-family: outfit;
  font-size: 40px;
}

.top-small-shadow {
  color: #767676;
}

.new-sec-title {
  font-family: outfit;
  font-size: 50px;
  font-weight: 400;
  text-transform: capitalize;
  color: #2B4248;
}

.new-list-paras {
  padding-right: 50px;
  color: #767676;
  font-size: 20px;
}

.about-third {
  padding-left: 80px;
}

.about-third-title {
  font-family: outfit;
  color: #2B4248;
}

.about-third p {
  color: #767676;
  font-size: 20px;
}

.banner-box button {
  background-color: #C9A45D;
  border: none;
  outline: none;
  padding: 10px;
  color: #fff;
  margin-top: 10px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width:768px) {
  .center-text h1 {
    font-size: 50px;
  }

  .center-text {
    height: 100vh;
  }

  .top-hero-section {
    height: 100vh;
  }

  .torque-Text h2 {
    font-size: 60px;
  }

  .slider-top-con h2 {
    font-size: 25px;
  }

  .arcitech-text {
    font-size: 30px;
  }

  .section-title {
    font-size: 20px;
  }

  .card-size {
    width: 100%;
  }

  .cards {
    width: 100%;
  }

  .toggle-title {
    font-size: 20px;
  }

  .toggleIcon {
    right: 0;
  }

  .mob-img {
    margin-top: 20px;
  }

  .check-mob {
    flex-direction: column;
  }

  video {
    height: 50vh;
  }

  .center-footer-text {
    font-size: 30px;
    height: 50vh;
  }

  .navbar-toggler {
    border: none !important;
    /* Remove border */
    background: none !important;
    /* Remove background if needed */
    outline: none !important;
    /* Remove focus outline */
    box-shadow: none !important;
    /* Remove box shadow if present */
  }

  footer {
    height: 110vh;
  }

  footer ul li {
    white-space: none;
  }

  .service-main-text h2 {
    font-size: 40px;
  }

  .service-sec-title h2 {
    font-size: 40px;
  }


  .service-main-text p .service-sec-title p {
    font-size: 16px;
  }

  .testi-box-con {
    width: 95%;
  }

  .fourth-sec-title {
    font-size: 30px;
  }

  .service-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    font-size: 8px;
    background-color: var(--primary-color);
    border-radius: 10px;
    color: #fff;
    padding:0 2px;
  }
  .active-ser,.ser-nav:hover{
    border-bottom: 2px solid #fff;
    font-weight: 100;
    cursor: pointer;
  }

  #basic-navbar-nav {
    background-color: rgb(0, 0, 0, 0.9);
    padding-top: 100px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;

  }

  .mob-arrow {
    display: none;
  }

  .nav-links {
    width: fit-content;
    margin-bottom: 20px;
  }

  .testi-content {
    height: auto;
    padding: 10px;
  }

  .quote-mark {
    margin-top: 10px;
  }

  .name-desc h6 {
    font-size: 14px;
  }

  footer ul li {
    padding: 0px 10px;
    white-space: none;
    font-size: 14px;
  }

  /* about sec */
  .new-sec-title {
    font-size: 30px;
  }

  .new-list-paras {
    font-size: 16px;
    padding-right: 0px;
  }

  .about-third {
    padding-left: 0px;
  }

  .about-third p {
    font-size: 16px;
  }

  .banner-proj-indu,
  .about-bann {
    margin-top: 50px;
    height: 50vh;
  }

  .banner-box h2 {
    font-size: 20px;
  }

  .banner-box {
    width: 80%;
    margin: 0 auto;
    height: auto;
    padding: 20px;
  }

  .project-showcase {
    display: flex;
  }

  .cardss {
    width: 80%;
    margin: 0 auto;
  }

  .card-second-img {
    display: none;
  }

  .project-title-section {
    font-size: 30px;
  }

  .project-sec-para {
    padding-left: 0;
    font-size: 16px;
  }

  .mob-img {
    margin-bottom: 20px;
  }

  .project-banner-sec {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-banner-sec h2 {
    font-size: 20px;
    text-align: center;
  }

  .project-banner-sec button {
    margin-top: 10px;
  }

  .projindu-part {
    padding-left: 0px;
    font-size: 16px;
    margin-top: 10px;
  }

  .mob-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .mob-list li {
    width: 50%;
  }

  .arcitech-text::after {
    display: none;
  }

  .sec-mob-overleap {
    position: sticky;
    top: 30vh;
  }

  .overleap {
    position: relative;
    top: 30px;
    height: 100vh;
  }

  .nav-icon {
    font-size: 40px;
  }

  .top-section {
    height: 50vh;
  }

  .services-third-sec {
    height: 50vh;
  }

  .testi-profile img {
    width: 40px;
    height: 40px;
  }

  .mob-sec-p {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.1cm;
  }

  .footer-fz {
    max-width: 300px;
    margin: auto;
  }

  .form-box input{
    width: 80%;
  }


}


/* HTML: <div class="loader"></div> */
.preloader{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  animation: l8-0 1.5s infinite linear;
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  background: var(--primary-color);
  clip-path: polygon(0 0%, 100% 0, 100% 100%);
  animation: inherit;
  animation-name: l8-1;
}

.loader:after {
  --s: -1;
}

@keyframes l8-0 {
  66% {
    transform: skewX(0deg)
  }

  80%,
  100% {
    transform: skewX(-45deg)
  }
}

@keyframes l8-1 {
  0% {
    transform: scale(var(--s, 1)) translate(-0.5px, 0)
  }

  33% {
    transform: scale(var(--s, 1)) translate(calc(1px - 50%), calc(1px - 50%))
  }

  66% {
    transform: scale(var(--s, 1)) translate(calc(1px - 50%), 0%)
  }

  100% {
    transform: scale(var(--s, 1)) translate(calc(0.5px - 50%), 0%)
  }
}

.contactform{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-box{
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.close-btn{
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;

}

.service-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;
  font-size: 22px;
  background-color: var(--primary-color);
  border-radius: 20px;
  color: #fff;
  padding:10px 20px;
}
.active-ser,.ser-nav:hover{
  border-bottom: 2px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

